<template>
    <div id="game-left-area">
      <TabDefault style="padding-right:20px;"/>

      <div v-if="datas.length==0">
        <b-row class="league-area py-5 m-0">
          <b-col class="col-12 ta-center">등록된 경기가 존재하지 않습니다</b-col>
        </b-row>
      </div>

      <div :key="i" v-for="(item, i) in datas" class="">
        <b-row class="league-area p-0 m-0" v-if="i == 0 || (i > 0 && item.league != datas[i-1].league)">
          <b-col class="d-flex left b-first p-0 m-0 col-12 col-sm-12 league-name">
            <b-img class="country-img ml-05" :src="makeLeagueImg(item)"></b-img>

            <span class="ml-h">{{ getSportsImg(item.sports_id)}}</span>
            <span class="ml-h">{{ (item.league_code)?item.league_code:item.league }}</span>
          </b-col>
        </b-row>

        <b-row class="game-list-data p-0 m-0" id="game_list">
          <b-col class="d-none d-md-flex col-md-1 center b-first m-0 p-0">
            <div class="m-0 p-0 ta-center" style="color:#ffe588;">
              <span>{{ getYear(item.time) }}</span>-<span>{{ getDay(item.time) }}</span> <span>{{ getTime(item.time) }}</span>
            </div>
          </b-col>

          <b-col class="d-flex d-md-none center b-first m-0 p-0" v-if="i == 0 || (i > 0 && item.league != datas[i-1].league) || (i > 0 && item.time != datas[i-1].time)">
            <div class="m-0 p-0 ta-center" style="color:#ffe588;">
              <span>{{ getYear(item.time) }}</span>-<span>{{ getDay(item.time) }}</span> <span>{{ getTime(item.time) }}</span>
            </div>
          </b-col>

          <b-col class="col-12 col-md-11 m-0 p-0" style="width:100%;">
            <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 1" title="축구">
              <b-col cols="4" class="ta-right p-0 odds-rate btn_rate"><span style="float:left;" class="team-name">{{ item.home_ko }}</span><span><input v-model="item.smp[0].odds" :class="'bet-id-'+item.smp[0].id+' '+item.id" @change="onChange"></span></b-col>
              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate"><input v-model="item.smp[1].odds" :class="'bet-id-'+item.smp[1].id+' '+item.id" @change="onChange"></b-col>
              <b-col cols="4" class="ta-left p-0 odds-rate btn_rate"><span><input v-model="item.smp[2].odds" :class="'bet-id-'+item.smp[2].id+' '+item.id" @change="onChange"></span><span style="float:right;" class="team-name">{{ item.away_ko }}</span></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == false"><feather-icon icon="PlusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == true"><feather-icon icon="MinusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-else></b-col>
            </b-row>
            <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 16 " title="야구">
              <b-col cols="4" class="ta-right p-0 odds-rate btn_rate"><span style="float:left;" class="team-name">{{ item.away_ko }}</span><span><input v-model="item.smp[1].odds" :class="'bet-id-'+item.smp[1].id+' '+item.id" @change="onChange"></span></b-col>
              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">VS</b-col>
              <b-col cols="4" class="ta-left p-0 odds-rate btn_rate"><span><input v-model="item.smp[0].odds" :class="'bet-id-'+item.smp[0].id+' '+item.id" @change="onChange"></span><span style="float:right;" class="team-name">{{ item.home_ko }}</span></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == false"><feather-icon icon="PlusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == true"><feather-icon icon="MinusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-else></b-col>
            </b-row>
            <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 17" title="아이스하키">
              <b-col cols="4" class="ta-right p-0 odds-rate btn_rate"><span style="float:left;" class="team-name">{{ item.away_ko }}</span><span><input v-model="item.smp[2].odds" :class="'bet-id-'+item.smp[2].id+' '+item.id" @change="onChange"></span></b-col>
              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate"><input v-model="item.smp[1].odds" :class="'bet-id-'+item.smp[1].id+' '+item.id" @change="onChange"></b-col>
              <b-col cols="4" class="ta-left p-0 odds-rate btn_rate"><span><input v-model="item.smp[0].odds" :class="'bet-id-'+item.smp[0].id+' '+item.id" @change="onChange"></span><span style="float:right;" class="team-name">{{ item.home_ko }}</span></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == false"><feather-icon icon="PlusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == true"><feather-icon icon="MinusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-else></b-col>
            </b-row>
            <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 18 " title="농구">
              <b-col cols="4" class="ta-right p-0 odds-rate btn_rate"><span style="float:left;" class="team-name">{{ item.away_ko }}</span><span><input v-model="item.smp[1].odds" :class="'bet-id-'+item.smp[1].id+' '+item.id" @change="onChange"></span></b-col>
              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">VS</b-col>
              <b-col cols="4" class="ta-left p-0 odds-rate btn_rate"><span><input v-model="item.smp[0].odds" :class="'bet-id-'+item.smp[0].id+' '+item.id" @change="onChange"></span><span style="float:right;" class="team-name">{{ item.home_ko }}</span></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == false"><feather-icon icon="PlusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == true"><feather-icon icon="MinusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-else></b-col>
            </b-row>
            <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 91 " title="배구">
              <b-col cols="4" class="ta-right p-0 odds-rate btn_rate"><span style="float:left;" class="team-name">{{ item.home_ko }}</span><span><input v-model="item.smp[0].odds" :class="'bet-id-'+item.smp[0].id+' '+item.id" @change="onChange"></span></b-col>
              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">VS</b-col>
              <b-col cols="4" class="ta-left p-0 odds-rate btn_rate"><span><input v-model="item.smp[1].odds" :class="'bet-id-'+item.smp[1].id+' '+item.id" @change="onChange"></span><span style="float:right;" class="team-name">{{ item.away_ko }}</span></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == false"><feather-icon icon="PlusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == true"><feather-icon icon="MinusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-else></b-col>
            </b-row>
            <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 162 " title="MMA">
              <b-col cols="4" class="ta-right p-0 odds-rate btn_rate"><span style="float:left;" class="team-name">{{ item.home_ko }}</span><span><input v-model="item.smp[0].odds" :class="'bet-id-'+item.smp[0].id+' '+item.id" @change="onChange"></span></b-col>
              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">VS</b-col>
              <b-col cols="4" class="ta-left p-0 odds-rate btn_rate"><span><input v-model="item.smp[1].odds" :class="'bet-id-'+item.smp[1].id+' '+item.id" @change="onChange"></span><span style="float:right;" class="team-name">{{ item.away_ko }}</span></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == false"><feather-icon icon="PlusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-on:click="subGame(item, i)" v-if="item.cnt > 1 && item.mode == true"><feather-icon icon="MinusSquareIcon"/></b-col>
              <b-col cols="2" class="ta-center p-0 bg2 btn-more" v-else></b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- sub -->
     
        <b-row class="game-list-data p-0 m-0" :key="j" v-for="(subGames, j) in item.sub">
          <b-col class="col-0 col-md-1 center m-0 p-0"></b-col>

          <b-col class="col-12 col-md-11 m-0 p-0" style="width:100%;">
            <!--축구 시작-->
            <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 1" title="축구">
              <b-col v-if="item.sports_id == 1" cols="4" class="ta-right p-0 odds-rate btn_rate">
                <span style="float:left;" class="team-name">{{ subGames.home_ko }}</span>
                <span v-if="subGames.odds_type == 'Handicap'" style="color:yellow;" class="mr-05">H</span>
                <span v-else-if="subGames.odds_type == 'Over/Under'" style="color:red;" class="mr-05">▲</span>
                <span><input v-model="subGames.smp[0].odds" :class="'bet-id-'+subGames.smp[0].id+' '+subGames.id" @change="onChange"></span>
              </b-col>
              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">
                <span v-if="subGames.odds_type == 'Handicap'">{{ subGames.smp[0].handicap }}</span>
                <span v-else>{{ subGames.smp[0].name }}</span>
              </b-col>
              <b-col cols="4" class="ta-left p-0 odds-rate btn_rate">
                <span><input v-model="subGames.smp[1].odds" :class="'bet-id-'+subGames.smp[1].id+' '+subGames.id" @change="onChange"></span>
                <span v-if="subGames.odds_type == 'Handicap'" style="color:yellow;" class="ml-05">H</span>
                <span v-else-if="subGames.odds_type == 'Over/Under'" style="color:blue;" class="ml-05">▼</span>
                <span style="float:right;" class="team-name">{{ subGames.away_ko }}</span></b-col>
            </b-row>
            <!--축구 끝-->

            <!--야구 시작-->
            <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 16" title="야구">
              <b-col v-if="subGames.odds_type == 'Handicap'" cols="4" class="ta-right p-0 odds-rate btn_rate">
                <span style="float:left;" class="team-name">{{ subGames.away_ko }}</span><span style="color:yellow;" class="mr-05">H</span><span><input v-model="subGames.smp[1].odds" :class="'bet-id-'+subGames.smp[1].id+' '+subGames.id" @change="onChange"></span>
              </b-col>
              <b-col v-else-if="subGames.odds_type == 'Over/Under'" cols="4" class="ta-right p-0 odds-rate btn_rate">
                <span style="float:left;" class="team-name">{{ subGames.away_ko }}</span><span  style="color:red;" class="mr-05">▲</span><span><input v-model="subGames.smp[0].odds" :class="'bet-id-'+subGames.smp[0].id+' '+subGames.id" @change="onChange"></span>
              </b-col>

              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">
                <span v-if="subGames.odds_type == 'Handicap'">{{ subGames.smp[1].handicap }}</span>
                <span v-else>{{ subGames.smp[1].handicap.replace('U ', '').replace('O ','') }}</span>
              </b-col>

              <b-col v-if="subGames.odds_type == 'Handicap'" cols="4" class="ta-left p-0 odds-rate btn_rate">
                <span><input v-model="subGames.smp[0].odds" :class="'bet-id-'+subGames.smp[0].id+' '+subGames.id" @change="onChange"></span><span style="color:yellow;" class="ml-05">H</span><span style="float:right;" class="team-name">{{ subGames.home_ko }}</span>
              </b-col>
              <b-col v-else-if="subGames.odds_type == 'Over/Under'" cols="4" class="ta-left p-0 odds-rate btn_rate">
                <span><input v-model="subGames.smp[1].odds" :class="'bet-id-'+subGames.smp[1].id+' '+subGames.id" @change="onChange"></span><span  style="color:blue;" class="ml-05">▼</span><span style="float:right;" class="team-name">{{ subGames.home_ko }}</span>
              </b-col>
            </b-row>

            <!--농구 시작-->
            <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 18" title="농구">
              <b-col v-if="subGames.odds_type == 'Handicap'" cols="4" class="ta-right p-0 odds-rate btn_rate">
                <span style="float:left;" class="team-name">{{ subGames.away_ko }}</span><span style="color:yellow;" class="mr-05">H</span><span><input v-model="subGames.smp[1].odds" :class="'bet-id-'+subGames.smp[1].id+' '+subGames.id" @change="onChange"></span>
              </b-col>
              <b-col v-else-if="subGames.odds_type == 'Over/Under'" cols="4" class="ta-right p-0 odds-rate btn_rate">
                <span style="float:left;" class="team-name">{{ subGames.away_ko }}</span><span  style="color:red;" class="mr-05">▲</span><span><input v-model="subGames.smp[0].odds" :class="'bet-id-'+subGames.smp[0].id+' '+subGames.id" @change="onChange"></span>
              </b-col>

              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">
                <span v-if="subGames.odds_type == 'Handicap'">{{ subGames.smp[1].handicap }}</span>
                <span v-else>{{ subGames.smp[1].handicap.replace('U ', '').replace('O ','') }}</span>
              </b-col>

              <b-col v-if="subGames.odds_type == 'Handicap'" cols="4" class="ta-left p-0 odds-rate btn_rate">
                <span><input v-model="subGames.smp[0].odds" :class="'bet-id-'+subGames.smp[0].id+' '+subGames.id" @change="onChange"></span><span style="color:yellow;" class="ml-05">H</span><span style="float:right;" class="team-name">{{ subGames.home_ko }}</span>
              </b-col>
              <b-col v-else-if="subGames.odds_type == 'Over/Under'" cols="4" class="ta-left p-0 odds-rate btn_rate">
                <span><input v-model="subGames.smp[1].odds" :class="'bet-id-'+subGames.smp[1].id+' '+subGames.id" @change="onChange"></span><span  style="color:blue;" class="ml-05">▼</span><span style="float:right;" class="team-name">{{ subGames.home_ko }}</span>
              </b-col>
            </b-row>

            <!--배구 시작-->
            <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 91" title="배구">
              <b-col v-if="subGames.odds_type == 'Handicap'" cols="4" class="ta-right p-0 odds-rate btn_rate">
                <span style="float:left;" class="team-name">{{ subGames.home_ko }} (세트)</span><span style="color:yellow;" class="mr-05">H</span><span><input v-model="subGames.smp[0].odds" :class="'bet-id-'+subGames.smp[0].id+' '+subGames.id" @change="onChange"></span>
              </b-col>
              <b-col v-else-if="subGames.odds_type == 'Over/Under - Point'" cols="4" class="ta-right p-0 odds-rate btn_rate">
                <span style="float:left;" class="team-name">{{ subGames.home_ko }} (점수합)</span><span  style="color:red;" class="mr-05">▲</span><span><input v-model="subGames.smp[0].odds" :class="'bet-id-'+subGames.smp[0].id+' '+subGames.id" @change="onChange"></span>
              </b-col>

              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">
                <span v-if="subGames.odds_type == 'Handicap'">{{ subGames.smp[0].handicap }}</span>
                <span v-else>{{ subGames.smp[0].handicap.replace('U ', '').replace('O ','') }}</span>
              </b-col>

              <b-col v-if="subGames.odds_type == 'Handicap'" cols="4" class="ta-left p-0 odds-rate btn_rate">
                <span><input v-model="subGames.smp[1].odds" :class="'bet-id-'+subGames.smp[1].id+' '+subGames.id" @change="onChange"></span><span style="color:yellow;" class="ml-05">H</span><span style="float:right;" class="team-name">(세트) {{ subGames.away_ko }}</span>
              </b-col>
              <b-col v-else-if="subGames.odds_type == 'Over/Under - Point'" cols="4" class="ta-left p-0 odds-rate btn_rate">
                <span><input v-model="subGames.smp[1].odds" :class="'bet-id-'+subGames.smp[1].id+' '+subGames.id" @change="onChange"></span><span  style="color:blue;" class="ml-05">▼</span><span style="float:right;" class="team-name">(점수합) {{ subGames.away_ko }}</span>
              </b-col>
            </b-row>

            <!--아이스하키 시작-->
            <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 17" title="아이스하키">
              <b-col v-if="subGames.odds_type == 'Handicap'" cols="4" class="ta-right p-0 odds-rate btn_rate">
                <span style="float:left;" class="team-name">{{ subGames.away_ko }}</span><span style="color:yellow;" class="mr-05">H</span><span><input v-model="subGames.smp[1].odds" :class="'bet-id-'+subGames.smp[1].id+' '+subGames.id" @change="onChange"></span>
              </b-col>
              <b-col v-else-if="subGames.odds_type == 'Over/Under'" cols="4" class="ta-right p-0 odds-rate btn_rate">
                <span style="float:left;" class="team-name">{{ subGames.away_ko }}</span><span  style="color:red;" class="mr-05">▲</span><span><input v-model="subGames.smp[0].odds" :class="'bet-id-'+subGames.smp[0].id+' '+subGames.id" @change="onChange"></span>
              </b-col>

              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">
                <span v-if="subGames.odds_type == 'Handicap'">{{ subGames.smp[1].handicap }}</span>
                <span v-else>{{ subGames.smp[1].handicap.replace('U ', '').replace('O ','') }}</span>
              </b-col>

              <b-col v-if="subGames.odds_type == 'Handicap'" cols="4" class="ta-left p-0 odds-rate btn_rate">
                <span><input v-model="subGames.smp[0].odds" :class="'bet-id-'+subGames.smp[0].id+' '+subGames.id" @change="onChange"></span><span style="color:yellow;" class="ml-05">H</span><span style="float:right;" class="team-name">{{ subGames.home_ko }}</span>
              </b-col>
              <b-col v-else-if="subGames.odds_type == 'Over/Under'" cols="4" class="ta-left p-0 odds-rate btn_rate">
                <span><input v-model="subGames.smp[1].odds" :class="'bet-id-'+subGames.smp[1].id+' '+subGames.id" @change="onChange"></span><span  style="color:blue;" class="ml-05">▼</span><span style="float:right;" class="team-name">{{ subGames.home_ko }}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
       
      </div>
    </div>

</template>

<script>

import { BRow, BCol, BImg, BContainer, BButton, BPagination } from 'bootstrap-vue'
import EventBus from '../../event-bus';
import TabDefault from './TabDefault2.vue'
import { getYear, getDay, getTime, makeLeagueImg, makeTeamImg } from '@core/utils/utils'

export default {
  data () {
    return {
      datas: [],
      odds_name: 'Full Time Result',
      currentPage: 1,
      perPage: 50,
      rows: 0,
      sports_id: 0, 
      users: [],
    }
  },
  components: {
    BRow, BCol, BImg, BContainer,BButton,TabDefault,BPagination
  },
  created () {
    EventBus.$on('add_bet', (id)=>
    {
      var bet_id = document.querySelector("#game-left-area .bet-id-"+id);
      if( bet_id != null)
      {
          bet_id.classList.add("selected");
      }
    });

    EventBus.$on('remove_bet', (id)=>
    {
      var bet_id = document.querySelector("#game-left-area .bet-id-"+id);
      if( bet_id != null)
      {
          bet_id.classList.remove("selected");
      }
    });

    EventBus.$on('next_page', ()=>
    {
      this.getDataAdd();
    });

  },
  mounted(){
    if( localStorage.getItem("userData") != null){
      this.users = JSON.parse(localStorage.getItem("userData"));
    }
    this.getData();
  },
  updated() {
    this.getBettingDataFromCookie();

    this.$nextTick(function () {
      // 모든 화면이 렌더링된 후 실행합니다.
    });
  },
  methods: {
    async onChange(e) {
      var id = e.target.classList[0].replace("bet-id-", "");
      var value = e.target.value;
      var game_id = e.target.classList[1];

      var results = await this.$api('http://server.honey-game.com/debug/debug/'+game_id+"/"+id+'/'+this.users.mem_id+'/'+value, 'get');
      console.log(results);
      if( results.result == 1 ){
        var bet_id = document.querySelector("#game-left-area .bet-id-"+id);
        if( bet_id ) {
          bet_id.style.backgroundColor = "lightpink";
        }
      } else {
        alert(results.msg);
      }
    },
    getSportsImg(id){
      if(id == 1) return '⚽';
      if(id == 16) return '⚾';
      if(id == 17) return '🏒';
      if(id == 18) return '🏀';
      if(id == 91) return '🏐';
      if(id == 162) return '👊';
    },
    async getData (page=1, sports_id=this.sports_id ) {
      this.sports_id = sports_id;
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'block';
      }
      var results = await this.$api('http://server.honey-game.com/games/prematch2/'+page+'/'+sports_id, 'get');

      this.datas = results.datas;
      this.rows = results.total_row;

      for(var i=0; i<this.datas.length; i++)
      {
        this.datas[i].mode = false;
        this.datas[i].sub = [];
      }

      // 배당 갱신
      var results2 = await this.$api('http://server.honey-game.com/debug/debug_list/'+this.users.mem_id, 'get');

      for( var i=0; i<results2.datas.length; i++ ){
        var bet_id = document.querySelector("#game-left-area .bet-id-"+results2.datas[i].rate_id);
        console.log(bet_id);
        if( bet_id ){
          bet_id.value = results2.datas[i].rate_value;
          bet_id.style.backgroundColor = "lightpink";
        } 
      }
      
      appLoading.style.display = 'none';
    },
    async getDataAdd () {

      if( (this.rows / 100) <= this.currentPage ) return;

      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'block';
      }
      var results = await this.$api('http://server.honey-game.com/games/prematch2/'+(++this.currentPage)+'/'+this.sports_id, 'get');
      this.datas = this.datas.concat(results.datas);

      // 배당 갱신
      var results2 = await this.$api('http://server.honey-game.com/debug/debug_list/'+this.users.mem_id, 'get');

      for(var i=(this.currentPage-1)*100; i<this.datas.length; i++)
      {
        this.datas[i].mode = false;
        this.datas[i].sub = [];
      }

      for( var i=0; i<results2.datas.length; i++ ){
        var bet_id = document.querySelector("#game-left-area .bet-id-"+results2.datas[i].rate_id);
        if( bet_id ){
          bet_id.value = results2.datas[i].rate_value;
          bet_id.style.backgroundColor = "lightpink";
        } 
      }

      appLoading.style.display = 'none';
    },
    getBettingDataFromCookie(){
      if( localStorage.getItem("betData") != null){
        var betData = JSON.parse(localStorage.getItem("betData"));
        for(var value of betData)
        {
          var bet_id = document.querySelector("#game-left-area .bet-id-"+value.selected.id);
          if( bet_id != null)
          {
            if( !bet_id.classList.contains('selected') ){
              bet_id.classList.add("selected");
            }
          }
        }
      }
    },
    async subGame(obj, idx){

      var results = await this.$api('http://server.honey-game.com/games/subGame/'+obj.id, 'get');

      if( this.datas[idx].mode == false ){
        for(var j = 0; j < results.datas.length; j++)
        {
          if( ( results.datas[j].sports_id == 16 || results.datas[j].sports_id == 17 || results.datas[j].sports_id == 18 ) && results.datas[j].odds_type == "Over/Under" ){
            results.datas[j].smp[0].header = "Over";
            results.datas[j].smp[1].header = "Under";

            results.datas[j].smp[0].name = results.datas[j].smp[0].handicap.replace('O ', '').replace('U ','');
            results.datas[j].smp[1].name = results.datas[j].smp[0].handicap.replace('O ', '').replace('U ','');
          }
        }
        this.datas[idx].mode = true;
        this.datas[idx].sub = results.datas;        
      } else {
        this.datas[idx].mode = false;
        this.datas[idx].sub = null;
      }
      this.$forceUpdate();

      // 배당 갱신
      var results2 = await this.$api('http://server.honey-game.com/debug/debug_list/'+this.users.mem_id, 'get');

      for( var i=0; i<results2.datas.length; i++ ){
        var bet_id = document.querySelector("#game-left-area .bet-id-"+results2.datas[i].rate_id);
        console.log(bet_id);
        console.log("#game-left-area .bet-id-"+results2.datas[i].rate_id);
        if( bet_id ){
          bet_id.value = results2.datas[i].rate_value;
          bet_id.style.backgroundColor = "lightpink";
        } 
      }
    },
    goCart(obj, j, event){

      var selected;
      selected = obj.smp[j];
      var bets = {
        'selected' : selected,
        'game_id' : obj.id,
        'league_cc' : obj.league_cc,
        'league' : obj.league,
        'league_code' :obj.league_code,
        'home' : obj.home_ko,
        'away' : obj.away_ko,
        'odds_name' : obj.odds_name,
        'odds_type' : obj.odds_type,
        'game_time' : obj.time,
        'sports_id' : obj.sports_id,
      }

      EventBus.$emit('update_cart', bets);
    },

    pageClick: function (button, page){
      this.getData(page);
      EventBus.$emit('upcoming-scroll-top');
		},

    getYear,
    getDay,
    getTime,
    makeLeagueImg,
    makeTeamImg,
  },
  beforeDestroy(){
    EventBus.$off();
  },
}
</script>

<style>
#game-left-area {width:calc(100%-10px);background-color:#242a3a !important;padding:10px;}
#game-left-area .league-area { background-color:#233054;margin:0px;color:white;font-family: "Noto Sans KR",sans-serif;border: 1px solid #111;}
#game-left-area .league-area>div {line-height:28px;align-items:center;}
#game-left-area .coulum { display: flex; flex-direction: column; flex-shrink: 1; flex-basis: auto; flex-wrap: nowrap; justify-content: center;align-items: baseline !important; }
#game-left-area .coulum .team-name {margin-left:7px;}
#game-left-area .odds-rate {line-height:36px;background-color:#13161e;margin:0px;border:1px solid #383636;cursor:pointer;color:white; padding:0px 8px !important;}
#game-left-area .btn-more {line-height:36px;background-color:#13161e;margin:0px;border:1px solid #383636;cursor:pointer;color:white; padding:0px 8px !important;}

#game-left-area .odds-rate.bg2 {background-color:#081332;}
@media (hover: hover){
  #game-left-area .odds-rate:hover { box-shadow: inset 0 0 0 1px #ffe588;transition: all .3s ease;}
}
#game-left-area .odds-rate.selected {box-shadow: inset 0 0 0 1px #ffe588;background-color:black !important;transition: all .3s ease;}
#game-left-area .game-list-data>div {display:flex;align-items: center;}
#game-left-area .center {justify-content: center;}
#game-left-area .sports_type { margin:2px;width:100px;padding:10px 0px;}

.b-pagination {justify-content:center;margin-top:10px;}

@media (max-width: 575px) {
  #game-left-area {
    padding:0px !important;
    padding-top: 10px !important;
    width:100% !important;
  }
  .app-content {
      padding: 0px !important;
      padding-top: 10px !important;
  }
}

.team-name {
  max-width:55%;
  white-space: nowrap; 
  text-overflow: ellipsis; 
  display:inline-block;
  overflow: hidden;
}

.league-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ml-05 {margin-left:5px;}
.mr-05 {margin-right:5px;}

@media (max-width: 768px) {
    .horizontal-layout .app-content {
      padding: 5.75rem 0rem 0 0rem !important;
    }
}

.dark-layout .horizontal-layout .header-navbar{ display:none; }
.horizontal-layout.app-content {padding:10px 0px !important;}
.header-navbar-shadow {display:none;}
</style>